import { gql } from "graphql-request"

export const finishPresentations = gql`
  mutation finishPresentations($dbId: ID!, $projectId: ID!, $starttime: ISO8601DateTime!, $timespent: Int!, $adjustedNumberOfParticipants: Int!, $rounds: Int!, $appointmentId: ID, $updateStatus: Boolean!) {
    finishPresentations(dbId: $dbId, projectId: $projectId, starttime: $starttime, timespent: $timespent, adjustedNumberOfParticipants: $adjustedNumberOfParticipants, rounds: $rounds, appointmentId:$appointmentId, updateStatus: $updateStatus) {
      finishedPresentations {
        ... on KernFinishedPresentation
        {
          id
        }
        ... on GskFinishedPresentation
        {
          id
        }
        ... on BayerFinishedPresentation
        {
          id
        }
      }
    }
  }
`
