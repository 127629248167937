import InputLabel from '../ui-components/InputLabel'
import InputMessage, { InputMessageKind } from '../ui-components/InputMessage'
import TextInput, { TextInputKind } from '../ui-components/TextInput'
import { ModalKind } from './Modal'
import Modal from './Modal'
import { Field, Formik } from 'formik'
import React, { useState } from 'react'
import { useModal } from '../contexts/modalContext'
import { ProjectForm } from '../ui-components/ProjectsModalElement'
import * as Yup from 'yup'
import { Appointment } from '../types/Appointment'
import { format } from 'date-fns'


interface Props {
  appointment: Appointment
}

const AddFinishPresentationModal: React.FC<Props> = ({ appointment }) => {
  const rawDate = new Date(appointment.date)
  const initialDate = format(rawDate, 'yyyy-MM-dd'); // "27-09-24"
  const initialTime = format(rawDate, 'HH:mm'); // "12:00"
  const [pharmacyName] = useState<string>(appointment.pharmacy.name)
  const [date, setDate] = useState<string>(initialDate)
  const [time, setTime] = useState<string>(initialTime)
  const [currentProject, setCurrentProject] = useState<number>(1)
  const { closeModal } = useModal()

  const initialFormValues = {
    name: pharmacyName,
    time: time,
    date: date,
  }

  const ModalSchema = Yup.object().shape({
    time: Yup.string()
      .min(4, 'Zu kurz!')
      .required('Bitte auswählen'),
      date: Yup.string()
      .min(8, 'Zu kurz!')
      .required('Bitte auswählen'),
  });


  const onFormSubmit = async () => {
    closeModal()
  }


  function filterOrderItems(appointment: Appointment) {
    const { completedProjects, orderItems } = appointment

    if (completedProjects === null) {
        return orderItems
    }

    const completedProjectsArray = completedProjects.split(', ').map(Number)
    const filteredItems = orderItems.filter(item => !completedProjectsArray.includes(item.project.id))

    return filteredItems
}

  const filteredProjects = filterOrderItems(appointment)

  return (
    <Modal kind={ModalKind.ms} title={'Schulung abschließen'} onClose={closeModal}>
      <Formik key="pharmacy-updated-form" initialValues={initialFormValues} enableReinitialize validationSchema={ModalSchema} onSubmit={onFormSubmit}>
        {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
          <form className="flex flex-wrap w-full">
            <div className="flex flex-col flex-wrap w-full">
              <div className="w-full p-0 sm:p-2">
                <div className="w-full flex flex-col">
                  <div className="px-6 py-0 sm:py-2">
									<InputLabel className={'flex flex-col items-center'}>{'Firmenname'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        type="text"
                        value={pharmacyName}
                        kind={TextInputKind.roundedModal}
                        name="name"
                      />
                      {errors.name && touched.name ? <InputMessage kind={InputMessageKind.error}>{errors.name}</InputMessage> : null}
                    </div>
                  </div>
                  <div className='flex flex-col sm:flex-row justify-between'>
                  <div className="w-full px-6 py-2">
									<InputLabel className={'flex flex-col items-center'}>{'Datum'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setDate(event.currentTarget.value)}
                        type="date"
                        value={date}
                        kind={TextInputKind.roundedModal} 
                        name="date"
                      />
                      {errors.date && touched.date ? <InputMessage kind={InputMessageKind.error}>{errors.date}</InputMessage> : null}
                    </div>
                  </div>
                  <div className="w-full px-6 py-2">
                    <InputLabel className={'flex flex-col items-center'}>{'Uhrzeit (24-Stunden-Format)'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <Field
                        className="form-input font-body text-gray-250 block w-full h-10 sm:h-14 rounded-full px-8 py-4 text-xs sm:text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400 sm:text-base sm:leading-5"
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setTime(event.currentTarget.value)}
                        type="time"
                        value={time}
                        kind={TextInputKind.roundedModal}
                      />
                    </div>
                  </div>
                  </div>
                  <div></div>
                </div>
              </div>
              <ProjectForm 
                project={filteredProjects ? filteredProjects[currentProject - 1] : []}
                current={currentProject} 
                total={filteredProjects.length}
                setCurrentProject={setCurrentProject}
                closeModal={closeModal}
                date={date}
                time={time}
                appointmentId={appointment.id}
                dbId={appointment.dbId}
              />
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

export default AddFinishPresentationModal