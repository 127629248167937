import React, { useState } from "react"
import InputLabel from "./InputLabel"
import TextInput, { TextInputKind } from "./TextInput"
import Button, { ButtonKind } from "./Button"
import CustomPagination from "./CustomPagination"
import { format, parseISO } from 'date-fns'
import { useAddFinishPresentations } from "../graphql/hooks/blockPharmacyProjectGroup"
import toast from "./NewToast"

type Props = {
  project: any
  current: number
  total: number
  setCurrentProject: React.Dispatch<React.SetStateAction<number>>
  closeModal: () => void
  date: string
  time: string
  appointmentId: number
  dbId: number
}

  export const ProjectForm: React.FC<Props> = ({ project, current, total, setCurrentProject, closeModal, date, time, appointmentId, dbId }) => {
  const [timespent, setTimespent] = useState<string>('')
  const [participants, setParticipants] = useState<string>('')
  const [rounds, setRounds] = useState<string>('')
  const { mutate: addFinishPresentations } = useAddFinishPresentations()
  const isVisiblePagination = current !== total ? 'flex' : 'hidden'
  const isVisibleButton = current === total ? 'flex' : 'hidden'
  const areNotValidated = (time.length < 4 || date.length < 8 || !participants.length || !timespent.length || !rounds.length) ? true : false

  const handleSubmit = (update: boolean) => {
    const formatDate = (date: string, time: string) => {

      const dateTimeString = `${date}T${time}:00`
      const parsedDate = parseISO(dateTimeString)
      
      const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss'Z'")
      
      return formattedDate
    }

    if (areNotValidated) {
      toast.show({
        headline: 'Bitte geben Sie alle Daten ein',
        type: 'error',
      })
      return 
    }

    formatDate(date, time)
    const formattedDateTime = formatDate(date, time)

    const variables = {
      dbId: dbId,
      projectId: project.project.id,
      starttime: formattedDateTime,
      timespent: +timespent * 60,
      adjustedNumberOfParticipants: +participants,
      rounds: +rounds,
      updateStatus: update,
      appointmentId: appointmentId
    }

    addFinishPresentations(variables)

    setTimespent('')
    setParticipants('')
    setRounds('')
    if (current === total) {
      closeModal()
    }
  }

  return (
    <>
    <div className="w-full p-0 sm:p-2">
    <div className='flex flex-col sm:flex-row '>
      <div className='w-full flex flex-col'>
        <div className="w-full px-6 py-2">
          <InputLabel className={'flex flex-col items-center'}>{'Schulungsthema'}</InputLabel>
          <div className="mt-1 rounded-md">
            <TextInput
              type="text"
              value={project.project.name}
              kind={TextInputKind.roundedModal}
            />
          </div>
        </div>
        <div className="w-full px-6 py-2">
          <InputLabel className={'flex flex-col items-center'}>{'Länge der Schulung (in Minuten)'}</InputLabel>
          <div className="mt-1 rounded-md">
            <TextInput
              onChange={(event: React.FormEvent<HTMLInputElement>) => setTimespent(event.currentTarget.value)}
              type="number"
              value={timespent}
              kind={TextInputKind.roundedModal}
            />
          </div>
        </div>
      </div>
      <div className='w-full flex flex-col'>
        <div className="w-full px-6 py-2">
          <InputLabel className={'flex flex-col items-center'}>{'Anzahl Teilnehmer'}</InputLabel>
          <div className="mt-1 rounded-md">
            <TextInput
              onChange={(event: React.FormEvent<HTMLInputElement>) => setParticipants(event.currentTarget.value)}
              type="number"
              value={participants}
              kind={TextInputKind.roundedModal}
            />
          </div>
        </div>
        <div className="w-full px-6 py-2">
          <InputLabel className={'flex flex-col items-center'}>{'Anzahl der Durchgänge'}</InputLabel>
          <div className="mt-1 rounded-md ">
            <TextInput
              onChange={(event: React.FormEvent<HTMLInputElement>) => setRounds(event.currentTarget.value)}
              type="number"
              value={rounds}
              kind={TextInputKind.roundedModal}
            />
          </div>
        </div>
      </div>
    </div>
    <span className="flex justify-center text-xxs sm:text-base4 font-medium font-semibold text-gray-450 tracking-wide m-2">{`Schulungsthema ${current} von ${total}`}</span>
    <div className={`${isVisibleButton} sticky self-end bottom-0 w-full justify-between p-0 sm:p-4 pt-4 pb-6 place-items-stretch`}>
      <Button 
				kind={ButtonKind.primaryModal} 
				onClick={() => handleSubmit(true)} 
				customStyle={'w-full text-xl'}
			>
        {'SCHULUNG ABSCHLIEßEN'}
      </Button>
    </div>
    <div className={`${isVisiblePagination}`}>
      <CustomPagination 
        {...(time.length < 4 || date.length < 8 || !participants.length || !timespent.length || !rounds.length
          ? undefined
          : { onChange: setCurrentProject })}
        current={current} 
        total={total} 
        handleSubmit={() => handleSubmit(false)}
      /> 
    </div>
    </div> 
    </>
  )
}