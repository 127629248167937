import { gql } from "graphql-request"

export const createAppointment = gql`
  mutation createAppointment(
    $pharmacyId: ID!,
    $dbId: ID!,
    $date: ISO8601DateTime!,
    $projectIds: [ID!]!,
    $amount: Int!,
  ) {
    createAppointment(
      pharmacyId: $pharmacyId,
      dbId: $dbId,
      date: $date,
      projectIds: $projectIds,
      amount: $amount,
    ) {
      id
    }
  }
`
